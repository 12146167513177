import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/forms/Buttons/Button';
import { landingSelect } from '../../../components/forms/Select/styles';
import Select from '../../../components/forms/Select/Select';
import { languages } from '../../../components/navigation/PublicNavBar/PublicNavBar';
import { useTranslation } from 'react-i18next';
import star from '../images/star.png';
import bil_logo from '../images/bil_logo.png';
import bil_logo_kz from '../images/bil_logo_kz.png';
import bil_girl_with_rectangle from '../images/bil_girl_with_rectangle.png';
import bil_guys from '../images/bil_guys.png';
import mob_logo from '../images/mob_logo.png';
import bil_girl from '../images/bil_girl.png';

const MainSection = () => {
  const [t, i18n] = useTranslation();

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <div className="flex h-screen  w-screen flex-col gap-4 bg-yellow-landing bg-[url('pages/Landing/images/page_1.svg')] bg-no-repeat">
      <div className='hidden flex-col lg:flex'>
        <div className='mt-10 flex flex-row items-center justify-around gap-12'>
          <img
            src={i18n.language === 'ru' ? bil_logo : bil_logo_kz}
            alt='Bil logo'
            className='h-[3.875rem] w-[13.3rem]'
          />
          <div className='flex flex-row items-center gap-4'>
            <Select
              className='w-40 text-center'
              customStyles={landingSelect}
              isSearchable={false}
              value={i18n.language}
              options={[
                ...languages?.map((o) => ({
                  label: o?.label,
                  value: o?.value,
                })),
              ]}
              onChange={(e) => handleLanguageChange(e.value)}
            />
            <a
              // href='https://drive.google.com/uc?export=download&id=18ybZfA1w2oxkXdVXpMjBWCUc-C8he6Tw'
              // download
              href='https://drive.google.com/file/d/18ybZfA1w2oxkXdVXpMjBWCUc-C8he6Tw/view?usp=drive_link'
              target='_blank'
              rel='noreferrer'
            >
              <Button
                className='rounded-xl'
                color='landing-blue'
                text={t('welcome.entrace_rules')}
              />
            </a>
          </div>
        </div>
        <div className='mt-20 ml-28 flex flex-row items-end justify-center gap-8'>
          <img
            height={322}
            width={180}
            src={bil_girl_with_rectangle}
            alt='bil student'
          />
          <div className='flex flex-col justify-center gap-4 px-3 sm:px-0'>
            <div className='w-full px-3 pb-4 font-sfproBold text-4xl sm:px-0 sm:text-5xl'>
              <p>{t('welcome.enter_exam_to_bil_1')}</p>
              <p className=''>“Білім-инновация”</p>
              <p>{t('welcome.enter_exam_to_bil_2')}</p>
            </div>
            <div className='flex w-full flex-row items-center gap-2 sm:gap-5'>
              <svg
                width='71'
                height='71'
                viewBox='0 0 71 71'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M70.9458 0.0461426H0.0292969V70.9958H70.9458V0.0461426Z'
                  fill='#ED7051'
                />
                <path
                  d='M70.9668 35.4998L35.4834 -0.000244141L-1.96066e-05 35.4998L35.4834 70.9998L70.9668 35.4998Z'
                  fill='#6C8CE4'
                />
                <path
                  d='M50.6523 35.6886L35.6182 20.6475L20.584 35.6886L35.6182 50.7298L50.6523 35.6886Z'
                  fill='white'
                />
              </svg>

              <div className='flex h-full w-4/5 flex-col bg-orange-landing  py-2 px-4 text-white sm:w-2/4'>
                <p className='text-sm'>{t('welcome.registration_period')}</p>
                <p className='font-sfproBold sm:text-2xl'>01.02-12.04.2025</p>
              </div>
            </div>
            <div className='flex w-full flex-row items-center gap-2 sm:gap-5'>
              <svg
                width='70'
                height='70'
                viewBox='0 0 70 70'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M69.3597 0H0V69.3921H69.3597V0Z' fill='#111233' />
                <path
                  d='M69.245 34.6415L34.8066 0.187012L0.36825 34.6415L34.8066 69.096L69.245 34.6415Z'
                  fill='#6C8CE4'
                />
                <path
                  d='M34.9747 17.0881L41.0723 28.24L52.2189 34.3404L41.0723 40.4408L34.9747 51.5927L28.8772 40.4408L17.7305 34.3404L28.8772 28.24L34.9747 17.0881Z'
                  fill='white'
                />
              </svg>
              <div className='flex h-full w-4/5 flex-col bg-black-landing  py-2 px-4 text-white sm:w-2/4'>
                <p className='text-sm'>{t('welcome.exam_date')}</p>
                <p className='font-sfproBold sm:text-2xl'>11.05.2025</p>
              </div>
            </div>
          </div>
          <img
            className='-translate-x-12 -translate-y-12 blur'
            height={124}
            width={124}
            src={star}
            alt='blured white start'
          />
          <svg
            className='-translate-x-8 -translate-y-24'
            width='162'
            height='162'
            viewBox='0 0 162 162'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M98.0453 80.8104C98.0453 90.3987 90.2841 98.1208 80.7727 98.1208C71.2612 98.1208 63.5 90.3987 63.5 80.8104C63.5 71.2222 71.2612 63.5 80.7727 63.5C90.2841 63.5 98.0453 71.2222 98.0453 80.8104Z'
              stroke='white'
              strokeWidth='127'
            />
          </svg>
        </div>
        <div className='ml-80 flex flex-row items-start justify-center py-4'>
          <div className='flex flex-col items-center'>
            <div className='flex flex-col justify-center gap-4 px-3 sm:px-0'>
              {/* <div className='flex w-full flex-row items-center gap-2 sm:gap-5'>
                <svg
                  width='70'
                  height='70'
                  viewBox='0 0 70 70'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M69.3597 0H0V69.3921H69.3597V0Z' fill='#111233' />
                  <path
                    d='M69.245 34.6415L34.8066 0.187012L0.36825 34.6415L34.8066 69.096L69.245 34.6415Z'
                    fill='#C9B291'
                  />
                  <path
                    d='M34.9747 17.0881L41.0723 28.24L52.2189 34.3404L41.0723 40.4408L34.9747 51.5927L28.8772 40.4408L17.7305 34.3404L28.8772 28.24L34.9747 17.0881Z'
                    fill='white'
                  />
                </svg>
                <div className='flex h-full w-4/5 flex-col bg-black-landing  py-2 px-4 text-white sm:w-2/4'>
                  <p className='text-sm'>{t('welcome.second_round')}</p>
                  <p className='font-sfproBold sm:text-2xl'>14.05.2024</p>
                </div>
              </div> */}
            </div>

            <div className='mt-4 flex w-full -translate-x-12 flex-row justify-between'>
              <div>
                <svg
                  className='translate-y-12'
                  width='81'
                  height='81'
                  viewBox='0 0 81 81'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M40.1654 0L54.3603 25.9751L80.3307 40.1841L54.3603 54.3857L40.1654 80.3682L25.9629 54.3857L0 40.1841L25.9629 25.9751L40.1654 0Z'
                    fill='white'
                  />
                </svg>
                <img
                  className='-translate-x-24'
                  height={124}
                  width={124}
                  src={star}
                  alt='white start'
                />
              </div>
              <div className='flex flex-col items-start px-3 '>
                {/* Normal version start */}
                {/* <p className='mb-4  text-2xl '>
                  {t('welcome.registration_period_with_date_1')}
                  <span className='font-bold '>
                    {t('welcome.registration_period_with_date_2')}{' '}
                  </span>
                  {t('welcome.registration_period_with_date_3')}
                </p>
                <Link to='/registration'>
                  <Button
                    className='h-14 rounded-xl text-2xl font-light'
                    color='landing-blue'
                    text={t('welcome.registration_to_exam')}
                  />
                </Link>
                <p className='mt-4 text-xl'>
                  {t('welcome.have_account')}{' '}
                  <Link to='/login'>
                    <span className='font-bold text-primary hover:underline'>
                      {t('welcome.login')}
                    </span>
                  </Link>
                </p> */}

                {/* Normal mob version end*/}
                {/* Closed mob version start*/}
                <p className='mb-4 flex flex-col px-36 text-left font-sfproBold text-xl'>
                  <span className='text-red-600'>
                    {t('welcome.registration_end_1')}
                  </span>
                  <span className='text-red-600'>
                    {t('welcome.registration_end_2')}
                  </span>

                  <span className='font-sfpro text-xl'>
                    {t('welcome.have_account')}{' '}
                    <Link to='/login'>
                      <span className='font-bold text-primary hover:underline'>
                        {t('welcome.login')}
                      </span>
                    </Link>
                  </span>
                  {/* <span>{t('welcome.registration_end_3')}</span> */}
                </p>

                <Link to='/registration' className='invisible'>
                  {/* invisible */}
                  <Button
                    className='h-14 rounded-xl text-2xl font-light'
                    color='error-solid'
                    text={t('welcome.registration_to_exam_closed')}
                  />
                </Link>
                {/* Closed mob version end*/}
              </div>
              <div>{/* не трогать это важная заглушка */}</div>
            </div>
          </div>
          <img
            className='-translate-y-12 -translate-x-4'
            width={300}
            height={380}
            src={bil_guys}
            alt='2 bil student'
          />
        </div>
      </div>
      {/*Mob version start
      
      
      
      */}
      <div className='mt-5 flex flex-col gap-5 lg:hidden'>
        <div className='flex flex-row justify-between px-6'>
          <img src={mob_logo} alt='logo' width={49} height={43} />
          <Select
            className='z-30 w-48 text-center'
            customStyles={landingSelect}
            isSearchable={false}
            value={i18n.language}
            options={[
              ...languages?.map((o) => ({
                label: o?.label,
                value: o?.value,
              })),
            ]}
            onChange={(e) => handleLanguageChange(e.value)}
          />
        </div>
        <div className='flex -translate-y-5 flex-row justify-end px-6'>
          <a
            // href='https://drive.google.com/uc?export=download&id=18ybZfA1w2oxkXdVXpMjBWCUc-C8he6Tw'
            // download
            href='https://drive.google.com/file/d/18ybZfA1w2oxkXdVXpMjBWCUc-C8he6Tw/view?usp=drive_link'
            target='_blank'
            rel='noreferrer'
          >
            <Button
              className='w-48 rounded-xl'
              color='landing-blue'
              text={t('welcome.entrace_rules')}
            />
          </a>
        </div>
        <div className='flex flex-row items-end justify-between px-8 '>
          <div className='flex flex-row '>
            <img className='h-14 w-14' src={star} alt={'star'} />
            <svg
              width='39'
              height='39'
              viewBox='0 0 39 39'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19.5 0L26.3916 12.6048L39 19.5L26.3916 26.3916L19.5 39L12.6048 26.3916L0 19.5L12.6048 12.6048L19.5 0Z'
                fill='white'
              />
            </svg>
          </div>
          <img src={bil_girl} width='93' height={151} alt='bil student' />
        </div>
        <div className='flex flex-col justify-center gap-4 px-3 sm:px-0'>
          <div className='w-full px-3 pb-4 font-sfproBold text-2xl sm:px-0 sm:text-5xl'>
            <p>{t('welcome.enter_exam_to_bil_1')}</p>
            <p className='keep- break-keep'>“Білім-инновация”</p>
            <p>{t('welcome.enter_exam_to_bil_2')}</p>
          </div>
          <div className='flex w-full flex-row items-center gap-2 sm:gap-5'>
            <svg
              width='71'
              height='71'
              viewBox='0 0 71 71'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M70.9458 0.0461426H0.0292969V70.9958H70.9458V0.0461426Z'
                fill='#ED7051'
              />
              <path
                d='M70.9668 35.4998L35.4834 -0.000244141L-1.96066e-05 35.4998L35.4834 70.9998L70.9668 35.4998Z'
                fill='#6C8CE4'
              />
              <path
                d='M50.6523 35.6886L35.6182 20.6475L20.584 35.6886L35.6182 50.7298L50.6523 35.6886Z'
                fill='white'
              />
            </svg>

            <div className='flex h-full w-4/5 flex-col bg-orange-landing  py-2 px-4 text-white sm:w-2/4'>
              <p className='text-sm'>{t('welcome.registration_period')}</p>
              <p className='font-sfproBold sm:text-2xl'>01.02-12.04.2025</p>
            </div>
          </div>
          <div className='flex w-full flex-row items-center gap-2 sm:gap-5'>
            <svg
              width='70'
              height='70'
              viewBox='0 0 70 70'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M69.3597 0H0V69.3921H69.3597V0Z' fill='#111233' />
              <path
                d='M69.245 34.6415L34.8066 0.187012L0.36825 34.6415L34.8066 69.096L69.245 34.6415Z'
                fill='#6C8CE4'
              />
              <path
                d='M34.9747 17.0881L41.0723 28.24L52.2189 34.3404L41.0723 40.4408L34.9747 51.5927L28.8772 40.4408L17.7305 34.3404L28.8772 28.24L34.9747 17.0881Z'
                fill='white'
              />
            </svg>
            <div className='flex h-full w-4/5 flex-col bg-black-landing  py-2 px-4 text-white sm:w-2/4'>
              <p className='text-sm'>{t('welcome.exam_date')}</p>
              <p className='font-sfproBold sm:text-2xl'>11.05.2025</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center gap-3 text-center'>
          {/* Normal version start*/}
          {/* <p className='text-lg'>
            {t('welcome.registration_period_with_date_1')}
            <span className='font-bold '>
              {t('welcome.registration_period_with_date_2')}{' '}
            </span>
            {t('welcome.registration_period_with_date_3')}
          </p>

          <Link to='/registration'>
            <Button
              className='h-14 rounded-xl font-light'
              color='landing-blue'
              text={t('welcome.registration_to_exam')}
            />
          </Link>
          <p className=' text-lg'>
            {t('welcome.have_account')}{' '}
            <Link to='/login'>
              <span className='font-bold text-primary hover:underline'>
                {t('welcome.login')}
              </span>
            </Link>
          </p> */}

          {/* Normal version end*/}
          {/* Closed version start*/}
          <p className=' flex flex-col px-4 text-left font-sfproBold'>
            <span className='text-red-600'>
              {t('welcome.registration_end_1')}
            </span>
            <span className='text-red-600'>
              {t('welcome.registration_end_2')}
            </span>
            <span className='font-sfpro text-lg'>
              {t('welcome.have_account')}{' '}
              <Link to='/login'>
                <span className='font-bold text-primary hover:underline'>
                  {t('welcome.login')}
                </span>
              </Link>
            </span>
            {/* <span>{t('welcome.registration_end_3')}</span> */}
          </p>
          {/* <Link to='/registration' className='invisible'> */}
          <Link to='/registration' className='invisible'>
            {/* invisible */}
            <Button
              className='mx-8 h-14 rounded-xl font-light'
              color='error-solid'
              text={t('welcome.registration_to_exam_closed')}
            />
          </Link>
          {/* Closed version end*/}
        </div>
        <div>{/* не трогать это важная заглушка */}</div>
      </div>
    </div>
  );
};

export default MainSection;
